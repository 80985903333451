import * as React from 'react'
import { Helmet } from 'react-helmet'

const MAPS_API_KEY = 'AIzaSyBejRcjpXkrWSLiD6Qd6UdGpndLo4xRUBM'

export const GoogleMap: React.FC<{ query: string; maptype?: 'satellite' }> = ({
  query,
  maptype = 'satellite',
}) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://www.google.com" />
      </Helmet>
      <iframe
        title="Karta"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/place?key=${MAPS_API_KEY}&q=${encodeURIComponent(
          query,
        )}&maptype=${maptype}`}
        allowFullScreen
      />
    </>
  )
}
