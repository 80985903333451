import * as React from 'react'
interface Day {
  opens: string
  closes: string
}
interface Days {
  Mon: Day
  Tue: Day
  Web: Day
  Thu: Day
  Wed: Day
  Fri: Day
  Sat: Day
  Sun: Day
}
export interface OpeningHoursProps {
  openingHours: string[] | string[][]
  type?: 'facebook' | 'ld'
}
const translateDays = (short: string) => {
  switch (short) {
    case 'Mon':
    case 'Mo':
      return 'Måndag'
    case 'Tue':
    case 'Tu':
      return 'Tisdag'
    case 'Wed':
    case 'We':
      return 'Onsdag'
    case 'Thu':
    case 'Th':
      return 'Torsdag'
    case 'Fri':
    case 'Fr':
      return 'Fredag'
    case 'Sat':
    case 'Sa':
      return 'Lördag'
    case 'Sun':
    case 'Su':
      return 'Söndag'
    default:
      return short
  }
}
const allDays: Array<keyof Days> = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const parseOpeningHours = (openingHours: string[]): Days => {
  return openingHours
    .map((day) => {
      const re = /^(\w\w\w?)-?(\w\w\w?)? (\d\d:\d\d)-(\d\d:\d\d)$/
      const match = day.match(re)
      if (!match) {
        return
      }
      return {
        startDay: match[1],
        endDay: match[2],
        startTime: match[3],
        endTime: match[4],
      }
    })
    .filter((d) => !!d)
    .reduce((acc, curr) => {
      if (curr.startDay) {
        let adding = false
        for (const day of allDays) {
          if (translateDays(day) === translateDays(curr.startDay)) {
            adding = true
          }
          if (adding) {
            acc[day] = { opens: curr.startTime, closes: curr.endTime } as Day
          }
          if ((adding && !curr.endDay) || translateDays(day) === translateDays(curr.endDay)) {
            break
          }
        }
      }
      return acc
    }, {} as Days)
}

const groupDays = (days: Days) => {
  const grouped = []
  let currentGroup: {
    first: string
    closed?: boolean
    last?: string
    opens?: string
    closes?: string
  }
  for (const key of allDays) {
    const day = days[key]
    if (day) {
      if (currentGroup && day.opens === currentGroup.opens && day.closes === currentGroup.closes) {
        currentGroup.last = key
      } else {
        currentGroup = {
          first: key,
          opens: day.opens,
          closes: day.closes,
        }
        grouped.push(currentGroup)
      }
    } else {
      if (currentGroup && currentGroup.closed) {
        currentGroup.last = key
      } else {
        currentGroup = {
          first: key,
          closed: true,
        }
        grouped.push(currentGroup)
      }
    }
  }
  return grouped
}
const titleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const parseFacebookOpeningHours = (hours: Array<string[]>): Days => {
  const a = hours.reduce((acc, curr) => {
    let [day, index, openOrClose] = curr[0].split('_')
    day = titleCase(day)
    if (!acc[day]) {
      acc[day] = {}
    }
    if (openOrClose === 'open') {
      acc[day].opens = curr[1]
    } else if (openOrClose === 'close') {
      acc[day].closes = curr[1]
    }
    return acc
  }, {} as any)
  return a
}

export const OpeningHours = ({ openingHours, type }: OpeningHoursProps) => {
  let days
  if (type === 'facebook') {
    days = parseFacebookOpeningHours(openingHours as string[][])
  } else {
    days = parseOpeningHours(openingHours as string[])
  }
  const groupedDays = groupDays(days)
  return (
    <table>
      <tbody>
        {groupedDays.map((group, i) => (
          <tr className={group.closed ? 'closed' : ''} key={i}>
            <td>
              {translateDays(group.first)} {group.last && `- ${translateDays(group.last)}`}
            </td>
            <td>{group.closed ? 'Stängt' : `${group.opens} - ${group.closes}`}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
