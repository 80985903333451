import * as React from 'react'
import Img from '../components/Img'
import { graphql } from 'gatsby'
import { Lazy } from 'react-lazy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWatch, faGift, faGraduationCap } from '@fortawesome/pro-duotone-svg-icons'

import { ImageSharp } from '../graphqlTypes'
import { OpeningHours } from '../components/openingHours'
import { Layout } from '../components/layout'
import { GoogleMap } from '../components/map'

interface IndexPageProps {
  data:
    | {
        pebImage: ImageSharp
        hours: any
      }
    | any
  location: any
  match: any
}
const IndexPage: React.SFC<IndexPageProps> = (props) => {
  console.log(props)
  return (
    <Layout {...props}>
      <section className="text">
        <h2 className="main-heading">Välkommen till regionens bästa urmakare</h2>
        <p>Vi finns vid Olofsfors bruk – ett av Sveriges bäst bevarade bruk!</p>
        <p>
          Olofsfors ligger endast några minuter från Nordmaling, mitt mellan Umeå och Örnsköldsvik.
        </p>
      </section>

      <section className="text opening-hours">
        <h2>Öppettider</h2>
        <OpeningHours
          openingHours={props.data.hours.edges.map((e: any) => e.node)[0].hours}
          type="facebook"
        />
        <p>Röda dagar är det stängt om inget annat anges</p>
      </section>

      <Lazy component="section" className="full-width map">
        <GoogleMap query={'Olofsfors urmakeri & hantverk'}></GoogleMap>
      </Lazy>

      <div className="blue">
        <section className="tri-icon">
          <h2 id="verksamhet">Verksamhet</h2>
          <ul>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faWatch} />
              </div>
              Försäljning, tillverkning och reparationer av alla typer av klockor
            </li>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faGift} />
              </div>
              Tillverkning och försäljning av smycken
            </li>
            <li>
              <div className="icon">
                <FontAwesomeIcon icon={faGraduationCap} />
              </div>
              Vidareutbildning för urmakare
            </li>
          </ul>
        </section>
      </div>
      <figure>
        <div className="img-wrapper">
          <Img sizes={props.data.pebImage.sizes} alt="Per-Erik i verkstaden" />
        </div>
        <figcaption>Per-Erik i verkstaden</figcaption>
      </figure>
    </Layout>
  )
}

export default IndexPage

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    pebImage: imageSharp(fluid: { originalName: { regex: "/peb/" } }) {
      sizes(maxWidth: 2560, quality: 90) {
        ...GatsbyImageSharpSizes_withWebp
      }
    }

    hours: allFacebookHours {
      edges {
        node {
          hours
        }
      }
    }
  }
`
